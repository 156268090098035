import React, { useCallback, useMemo, useState } from "react";
import { UilUserPlus } from '@iconscout/react-unicons';

import Form from "../../../../primitives/Form";
import Modal from "../../../../primitives/Modal";
import Button from "../../../../primitives/Button";
import LoaderSpinner from "../../../../primitives/LoaderSpinner";
import { ROLES } from "../../../../constants";
import { getUserStatuses } from "store/slices/constants";
import { getUserTypes } from "store/slices/constants";
import formFields from "../../fields.js";
import { useSelector } from "react-redux";

const CreateUser = ({ onCreateUser, clients, isUserSuperAdmin }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const userStatuses = useSelector(getUserStatuses);
    const userTypes = useSelector(getUserTypes);
    const { LIMIT_FORM_FIELD, MODAL_FORM_FIELDS, ROLE_FORM_FIELD, PASSWORD_FORM_FIELD, STATUS_FORM_FIELD, SUPERUSER_FORM_FIELD } = formFields({ userStatuses, userTypes });

    const handleModal = useCallback(() => {
        setIsModalOpen((open) => !open);
    }, []);

    const onSubmit = (values) => {
        return onCreateUser(values)
            .then((data) => {
                if (!data.error) setIsModalOpen(false);
                return data;
            });
    };

    const fields = useMemo(() => {
        if (!isUserSuperAdmin) return [...MODAL_FORM_FIELDS, PASSWORD_FORM_FIELD, LIMIT_FORM_FIELD, STATUS_FORM_FIELD];
        if (!clients.entities) return null;

        let result = [...MODAL_FORM_FIELDS, PASSWORD_FORM_FIELD];
        if (isUserSuperAdmin) {
            result.push(ROLE_FORM_FIELD);
            result.push({ ...SUPERUSER_FORM_FIELD, conditionalFields: [{ field: ROLE_FORM_FIELD.name, value: ROLES.SUPER_ADMIN }] })
            result.push({
                name: "client",
                labelText: "Клиент-владелец",
                type: "select",
                options: clients.entities.map((e) => ({ label: e.name, value: e.id })),
                required: false,
                conditionalFields: [
                    {field: ROLE_FORM_FIELD.name, value: [ROLES.USER, ROLES.CLIENT_ADMIN]}
                ]
            })
            result.push({ ...LIMIT_FORM_FIELD, conditionalFields: [{ field: ROLE_FORM_FIELD.name, value: ROLES.USER }] })
        }
        result.push(STATUS_FORM_FIELD);
        return result
    }, [clients, isUserSuperAdmin]);

    return (
        <>
            <Button icon={<UilUserPlus />} onClick={handleModal}>Создать пользователя</Button>
            <Modal isOpen={isModalOpen} onClose={handleModal} title="Создать пользователя">
                {clients.loading && <LoaderSpinner />}
                {fields && (
                    <Form
                        column
                        fields={fields}
                        buttonText="Создать"
                        buttonIcon={<UilUserPlus />}
                        onSubmit={onSubmit}
                    />
                )}
            </Modal>
        </>
    );
};

export default React.memo(CreateUser);
