import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import Select from '../Select';
import Button from "../Button";
import DateRangeInput from "../DateRangeInput";

import styles from './index.module.scss';

export interface FilterProps {
    filters: Filter[];
    applyFilters: (filters: SelectedFilters) => void;
    // selectedFilters: SelectedFilters;
    // setSelectedFilters: (filters: SelectedFilters) => void;
}

export enum FilterType {
    Select = 'select',
    DateRange = 'dateRange'
}

interface BaseFilter {
    label: string;
    field: string;
    type: FilterType;
}

export interface SelectFilter extends BaseFilter {
    type: FilterType.Select;
    options: { label: string; value: string }[];
}

export interface DateRangeFilter extends BaseFilter {
    type: FilterType.DateRange;
}

export type Filter = SelectFilter | DateRangeFilter;
export type SelectedFilters = Record<string, string | Date | null>

const FilterComponent: React.FC<FilterProps> = ({ filters, applyFilters/*,selectedFilters,setSelectedFilters*/ }) => {
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});

    const handleFilterChange = (field: string, value: string | Date | null) => {
        // setSelectedFilters({...selectedFilters,[field]:value});
        setSelectedFilters((prevState: SelectedFilters) => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleApplyFilters = () => {
        applyFilters(selectedFilters);
    };

    return (
        <div className={styles.FilterContainer}>
            <div className={styles.FilterFields}>
                {filters.map((filter, index) => (
                    <div key={index} className={styles.FilterItem}>
                        {filter.type === FilterType.Select && (
                            <Select
                                options={(filter as SelectFilter).options}
                                value={selectedFilters[filter.field]}
                                onChange={(value: string) => handleFilterChange(filter.field, value)}
                                placeholder="Выберите"
                                labelText={filter.label}
                                isCalculatedWidth
                            />
                        )}
                        {filter.type === FilterType.DateRange && (
                            <DateRangeInput
                                labelText={filter.label}
                                startDate={selectedFilters[`${filter.field}-start`] as Date | null}
                                endDate={selectedFilters[`${filter.field}-end`] as Date | null}
                                onChangeStartDate={(date) => handleFilterChange(`${filter.field}-start`, date)}
                                onChangeEndDate={(date) => handleFilterChange(`${filter.field}-end`, date)}
                            />
                        )}
                    </div>
                ))}
            </div>
            <Button onClick={handleApplyFilters} to={undefined} icon={undefined} className={undefined}
                uppercase={undefined} loading={undefined}>
                Применить
            </Button>
        </div>
    );
};

export default React.memo(FilterComponent);
