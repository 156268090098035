import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UilEdit, UilSync, UilTrash, UilUserPlus} from "@iconscout/react-unicons";

import {ROUTES_PATHS, ROUTES_TITLES} from "../../router/routes";
import {CLIENTS, createClient, getClients, refreshClientPackage, removeClient, updateClient} from "../../store/slices/clients";
import LoaderSpinner from "../../primitives/LoaderSpinner";
import Table from "../../primitives/Table";
import Form from "../../primitives/Form";
import Modal from "../../primitives/Modal";
import Button from "../../primitives/Button";
import styles from "../../App.module.scss";
import {getCompanyStatus, getCompanyStatuses, getPackageOption, getPackageType} from "store/slices/constants";
import {getPackages, PACKAGES} from "store/slices/packages";

const Clients = () => {
    const companyStatus = useSelector(getCompanyStatus);
    const companyStatuses = useSelector(getCompanyStatuses);
    const packageType = useSelector(getPackageType);
    const packageOption = useSelector(getPackageOption);
    const packages = useSelector(state => state[PACKAGES]);

    const COLUMNS = [{key: 'name', displayName: 'Название'}, {
        key: 'status',
        displayName: 'Статус',
        value: (item) => companyStatus(item.status)
    }, {
        key: 'package_type',
        displayName: 'Тип пакета',
        value: (item) => packageType(item.package?.package_type)
    }, {
        key: 'package_option',
        displayName: 'Опции',
        value: (item) => packageOption(item.package?.package_option)
    }, {key: 'req_limit', displayName: 'Лимит запросов', value: (item) => item.package?.limits}, {
        key: 'requests_available',
        displayName: 'Доступно запросов'
    },];

    const dispatch = useDispatch();
    const clients = useSelector(state => state[CLIENTS]);
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const [editingClient, setEditingClient] = useState(null);
    const [isCreatingOpen, setIsCreatingOpen] = useState(false);

    const CREATE_FIELDS = useMemo(() => [{
        name: "name", labelText: "Название", required: true, placeholder: 'ПАО "Газпромбанк"'
    }, {
        name: "package_id",
        labelText: "Пакет",
        required: true,
        type: "select",
        options: packages?.entities?.map(pack => ({value: pack.id, label: pack.name})),
    },], [packages]);

    const EDIT_FIELDS = useMemo(() => CREATE_FIELDS.concat([{
        name: "status",
        labelText: "Статус",
        type: "select",
        options: Object.keys(companyStatuses).map(key => ({value: key, label: companyStatuses[key]})),
        required: true,
        initialValue: "active"
    }, // {
        //     name: "req_limit",
        //     labelText: "Лимит запросов",
        //     placeholder: "1000",
        //     type: "number",
        //     required: true,
        // }
    ]), [packages, companyStatuses]);

    const openEditingModal = useCallback((id) => {
        setEditingClient(clients.entities.find((c) => c.id === id));
    }, [clients.entities]);
    const closeEditingModal = useCallback(() => setEditingClient(null), []);

    const openCreatingModal = useCallback(() => setIsCreatingOpen(true), []);
    const closeCreatingModal = useCallback(() => setIsCreatingOpen(false), []);

    const findPackage = useCallback((id) => id ? packages?.entities?.find(p => p.id === id) : undefined, [packages]);

    const handleCreateClient = useCallback((client) => {
        return dispatch(createClient({...client, status: "active", package: findPackage(client.package_id)}))
            .unwrap()
            .then(() => closeCreatingModal())
            .then(() => ({}))
            .catch((error) => ({error}));
    }, []);

    const handleUpdateClient = useCallback((client) => {
        return dispatch(updateClient({id: editingClient.id, ...client, package: findPackage(client.package_id)}))
            .unwrap()
            .then(() => ({}))
            .catch((error) => ({error}));
    }, [editingClient]);

    const refreshPackage = useCallback((id) => {
        return dispatch(refreshClientPackage(id))
            .then(() => ({}))
            .catch((error) => ({error}));
    });

    const handleRemoveClient = useCallback((id) => {
        return dispatch(removeClient(id))
            .then(() => ({}))
            .catch((error) => ({error}));
    });

    // const handleRemoveClient = useCallback((id) => {
    //     return dispatch(removeClient(id))
    //         .unwrap()
    //         .then(() => dispatch(getClients({page, pageSize})))  // Обновляем список клиентов
    //         .catch((error) => ({error}));
    // }, [dispatch, page, pageSize]);

    useEffect(() => {
        dispatch(getClients({page, pageSize}))
        dispatch(getPackages({page: 1, pageSize: 1000000}))
    }, [page, dispatch]);

    const actions = useMemo(() => [{
        key: 'edit', title: 'Редактировать', icon: <UilEdit size="20" color="#333333"/>, handle: openEditingModal
    }, {
        key: "refresh", title: "Обновить пакет", icon: <UilSync size="20" color="#333333"/>, handle: refreshPackage,
    }, {
        key: "remove", title: "Удалить", icon: <UilTrash size="20" color="#333333"/>, handle: handleRemoveClient,
    }], [openEditingModal, refreshPackage, handleRemoveClient]);

    const editingFields = useMemo(() => editingClient ? EDIT_FIELDS.map((f) => ({
        ...f, initialValue: editingClient[f.name] || f.initialValue
    })) : null, [packages, editingClient, companyStatuses]);

    // const translatedClients = useMemo(() => clients.entities ? clients.entities.map((c) => ({
    //     ...c,
    //     status: STATUSES_TITLES[c.status]
    // })) : null, [clients.entities]);

    return (<div>
            <h2 className={styles.MobileTitle}>{ROUTES_TITLES[ROUTES_PATHS.CLIENTS]}</h2>
            <Button icon={<UilUserPlus/>} onClick={openCreatingModal}>Создать клиента</Button>
            {(clients.loading && !clients.entities) && <LoaderSpinner/>}
            {clients.total && <Table data={clients.entities} columns={COLUMNS} actions={actions} total={clients.total} page={page}
                                     pageSize={pageSize} setPage={setPage}/>}
            <Modal isOpen={isCreatingOpen} onClose={closeCreatingModal} title="Добавить клиента">
                <Form
                    column
                    fields={CREATE_FIELDS}
                    buttonText="Сохранить"
                    buttonIcon={<UilUserPlus/>}
                    onSubmit={handleCreateClient}
                />
            </Modal>
            <Modal isOpen={editingClient} onClose={closeEditingModal} title="Изменить клиента">
                <Form
                    column
                    fields={editingFields}
                    buttonText="Сохранить"
                    buttonIcon={<UilUserPlus/>}
                    onSubmit={handleUpdateClient}
                />
            </Modal>
        </div>);
};

export default React.memo(Clients);
