import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import inputStyles from '../Input/index.module.scss';
import styles from './index.module.scss';

export interface IDateRangeInputProps {
    value: Date | null;
    onChange: (date: Date | null) => void;
    labelText?: string;
    placeholder?: string;
}

const DateRangeInput: React.FC<IDateRangeInputProps> = ({ value, onChange, labelText, placeholder = 'dd.mm.yyyy' }) => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    return (
        <label className={inputStyles.Label}>
            {labelText && (
                <span>{labelText}</span>
            )}
            <div className={styles.DateRange}>
                {isMobile ? (
                    <>
                        <input
                            type="date"
                            className={inputStyles.Input}
                            value={value ? value.toISOString().split('T')[0] : ''}
                            onChange={(e) => onChange(new Date(e.target.value))}
                        />
                    </>
                ) : (
                    <>
                        <div>
                            <DatePicker
                                className={inputStyles.Input}
                                selected={value}
                                onChange={onChange}
                                selectsStart
                                startDate={value}
                                endDate={value}
                                dateFormat="dd.MM.yyyy"
                                placeholderText={placeholder}
                            />
                        </div>
                    </>
                )}
            </div>
        </label>
    );
};

export default React.memo(DateRangeInput);
