import React from "react";

import styles from "./index.module.scss";
import Input from "../Input";

const GroupInput = ({labelText, inputs}) => {
    return (
        <label className={styles.Label}>
            <span>{labelText}</span>
            <div className={styles.Inputs}>
                {inputs.map((input, i) => <Input {...input} key={i}/>)}
            </div>
        </label>
    );
};

export default React.memo(GroupInput);
