import React from "react";
import {NavLink} from "react-router-dom";
import cn from "classnames";

import styles from "./index.module.scss";

const Tabs = ({tabs}) => {
    return (
        <div className={styles.Tabs}>
            {tabs.map((tab) => (
                <NavLink
                    to={tab.path}
                    className={({isActive}) =>
                        isActive ? cn(styles.Tab, styles.ActiveTab) : styles.Tab
                    }
                    key={tab.path}
                >
                    {tab.title}
                </NavLink>
            ))}
        </div>
    );
};

export default React.memo(Tabs);