import React, {useCallback, useRef, useState} from "react";
import {UilHouseUser} from "@iconscout/react-unicons";

import {ROUTES_PATHS, ROUTES_TITLES} from "../../../../router/routes";
import {ROLES} from "../../../../constants";
import useClickOutside from "../../../../hooks/useClickOutside";
import Button from "../../../../primitives/Button";
import styles from "./index.module.scss";

const UserNav = ({user, handleLogout}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleOpen = useCallback(() => setIsMenuOpen((open) => !open), [])

    const ref = useRef(null);

    useClickOutside({elementRef: ref, callback: () => setIsMenuOpen(false)});

    return (
        <nav ref={ref}>
            <Button variant="secondary" className={styles.User} onClick={handleOpen}>
                <UilHouseUser/>
                {user.shortName || user.name}
            </Button>
            {isMenuOpen && (
                <div className={styles.Menu}>
                    <Button variant="secondary" to={ROUTES_PATHS.SEARCH}>{ROUTES_TITLES[ROUTES_PATHS.SEARCH]}</Button>
                    <Button variant="secondary" to={ROUTES_PATHS.REPORTS}>
                        {ROUTES_TITLES[ROUTES_PATHS.REPORTS]}
                    </Button>
                    {user.role === ROLES.SUPER_ADMIN && [
                        (<Button key="clients" variant="secondary" to={ROUTES_PATHS.CLIENTS}>
                            {ROUTES_TITLES[ROUTES_PATHS.CLIENTS]}
                        </Button>),
                        (<Button key="packages" variant="secondary" to={ROUTES_PATHS.PACKAGES}>
                            {ROUTES_TITLES[ROUTES_PATHS.PACKAGES]}
                        </Button>)
                    ]}
                    {[ROLES.SUPER_ADMIN, ROLES.CLIENT_ADMIN].includes(user.role) && (
                        <>
                            <Button variant="secondary" to={ROUTES_PATHS.USERS}>
                                {ROUTES_TITLES[ROUTES_PATHS.USERS]}
                            </Button>
                        </>
                    )}
                    <Button variant="secondary" onClick={handleLogout}>Выход</Button>
                </div>
            )}
        </nav>
    );
};

export default React.memo(UserNav);
