import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';

const RangeInput = ({ value, onChange, labelText }) => {
    const [minValue, setMinValue] = useState(value[0]);
    const [maxValue, setMaxValue] = useState(value[1]);
    const [dragging, setDragging] = useState(false);
    const [activeThumb, setActiveThumb] = useState(null);
    const trackRef = useRef(null);

    useEffect(() => {
        setMinValue(value[0]);
        setMaxValue(value[1]);
    }, [value]);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (dragging && activeThumb !== null) {
                const rect = trackRef.current.getBoundingClientRect();
                const offsetX = event.clientX - rect.left;
                const rangeWidth = rect.width;
                let newValue = Math.round((offsetX / rangeWidth) * 100);
                newValue = Math.min(Math.max(newValue, 0), 100);
                if (activeThumb === 'min' && newValue < maxValue) {
                    setMinValue(newValue);
                    onChange([newValue, maxValue]);
                } else if (activeThumb === 'max' && newValue > minValue) {
                    setMaxValue(newValue);
                    onChange([minValue, newValue]);
                }
            }
        };

        const handleMouseUp = () => {
            setDragging(false);
            setActiveThumb(null);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging, activeThumb, minValue, maxValue, onChange]);

    const handleMouseDown = (thumb) => {
        setDragging(true);
        setActiveThumb(thumb);
    };

    return (
        <div className={styles.Label}>
            {labelText && <span>{labelText}</span>}
            <div className={styles.RangeInput} ref={trackRef}>
                <div className={styles.Track}>
                    <div className={styles.Progress} style={{ width: `${maxValue - minValue}%`, left: `${minValue}%` }}></div>
                    <div className={styles.Thumb} style={{ left: `${minValue}%` }} onMouseDown={() => handleMouseDown('min')}></div>
                    <div className={styles.Thumb} style={{ left: `${maxValue}%` }} onMouseDown={() => handleMouseDown('max')}></div>
                </div>
            </div>
            <div className={styles.Values}>
                <span>{minValue}</span> - <span>{maxValue}</span>
            </div>
        </div>
    );
};

export default React.memo(RangeInput);
