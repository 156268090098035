import {redirect} from "react-router-dom";
import {ROUTES_PATHS} from "./routes";

export const authLoader = (user, rolesProtection = []) => () => {
    if (!user) return redirect(ROUTES_PATHS.AUTH);
    if (rolesProtection.length && !rolesProtection.includes(user.role)) {
        return redirect(ROUTES_PATHS.AUTH);
    }
    return null;
}
