function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const payload = JSON.parse(jsonPayload);
    console.log(payload)
    return payload;
}

function isTokenExpiringSoon(token: string, thresholdMinutes = 0) {
    const { exp } = parseJwt(token);
    // console.log(exp)
    const currentTime = Math.floor(Date.now() / 1000); // Текущее время в секундах
    // console.log(currentTime)
    const expTime = exp - thresholdMinutes * 60; // Время истечения минус threshold
    // console.log(expTime)
    const expired = expTime < currentTime;
    // console.log(expired)
    return expired
}

export default isTokenExpiringSoon;
