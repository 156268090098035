import constantsReducer, {CONSTANTS} from './slices/constants';
import {configureStore} from '@reduxjs/toolkit'

import clientsReducer, {CLIENTS} from "./slices/clients";
import usersReducer, {USERS, UsersState} from "./slices/users";
import searchReducer, {SEARCH} from "./slices/search";
import packagesReducer, {PACKAGES} from "./slices/packages";
import {SearchState} from "./slices/search/types";
import authReducer, {AUTH, IAuthState} from "./slices/auth";
import {ROLES} from "../constants";

export interface IAuthUser {
    access_token?: string;
    refresh_token?: string;
    name: string;
    email?: string;
    id: number;
    status: 'active' | 'not_active';
    role: typeof ROLES[keyof typeof ROLES];
    token?: string;
    telegram_id?: string;
    telegram_chat_id?: string;
}

export interface IRootState {
    [CONSTANTS]: any,
    [AUTH]: IAuthState;
    [CLIENTS]: {
        entities: any;
        total: number;
        loading: boolean;
        creating: { loading: boolean; };
        updating: { loading: boolean; };
    };
    [USERS]: UsersState;
    [PACKAGES]: {
        entities: any;
        total: number;
        loading: boolean;
        creating: { loading: boolean; };
        updating: { loading: boolean; };
    };
    [SEARCH]: SearchState;
}

const store = configureStore<IRootState>({
    reducer: {
        [CONSTANTS]: constantsReducer,
        [AUTH]: authReducer,
        [CLIENTS]: clientsReducer,
        [USERS]: usersReducer,
        [SEARCH]: searchReducer,
        [PACKAGES]: packagesReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export default store;
