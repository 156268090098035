import Modal from "primitives/Modal";
import formStyles from "../../primitives/Form/index.module.scss";
import styles from "./index.module.scss";
import { UilCheckCircle, UilTimesCircle } from "@iconscout/react-unicons";
import Button from "primitives/Button";

const ConfirmModal = ({ title, text, handleYes, handleNo, isOpen, handleClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={handleClose} title={title}>
            <div className={styles.Text}>
                {text}
            </div>
            <div className={styles.Buttons}>
                <Button className={formStyles.Button} type="button" icon={<UilCheckCircle/>} onClick={handleYes} uppercase>
                    Да
                </Button>
                <Button className={formStyles.Button} type="button" icon={<UilTimesCircle />}  onClick={handleNo} uppercase>
                    Нет
                </Button>
                <Button className={formStyles.Button} type="button" icon={<UilTimesCircle />}  onClick={handleClose} uppercase>
                    Отмена
                </Button>
            </div>
        </Modal>
    );
};
export default ConfirmModal;