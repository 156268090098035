import React, {useCallback, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {UilSignin} from '@iconscout/react-unicons'

import {AUTH, login} from "../../store/slices/auth";
import {ROUTES_PATHS} from "../../router/routes";
import Form from "../../primitives/Form";

import styles from "./index.module.scss";
import {AppDispatch, IRootState, IAuthUser} from "../../store";

const Auth = () => {
    const user = useSelector<IRootState, IAuthUser | null>((state) => state[AUTH].entity);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const onSubmit = useCallback(async (values: Record<"email" | "password", string>) => {
        try {
            await dispatch(login(values)).unwrap();
        } catch (error) {
            return ({error});
        }
    }, []);

    const fields = useMemo(() => [
        {name: 'email', type: "email", labelText: "Email", placeholder: 'example@ifce.ru', required: true},
        {name: 'password', type: "password", labelText: "Пароль", required: true, description: <Link to={ROUTES_PATHS.FORGOT_PASSWORD}>Забыли пароль?</Link>}
    ], [])

    useEffect(() => {
        if (user) navigate(ROUTES_PATHS.SEARCH);
    }, [user]);

    return (
        <Form
            className={styles.Form}
            fields={fields}
            onSubmit={onSubmit}
            headerText="Авторизация"
            buttonText="Войти"
            buttonIcon={<UilSignin/>}
            column
        />
    );
};

export default React.memo(Auth);
