export default ({userStatuses, userTypes}) => ({
    LIMIT_FORM_FIELD: {
        name: "requests_limit",
        labelText: "Лимит запросов",
        placeholder: "1000",
        type: "number",
        required: true,
    },

    SUPERUSER_FORM_FIELD: {
        name: "is_superuser",
        labelText: "Суперюзер",
        type: "checkbox",
    },

    PASSWORD_FORM_FIELD: {
        name: "password",
        labelText: "Пароль",
        placeholder: "********",
        required: true,
    },

    NEW_PASSWORD_FORM_FIELD: {
        name: "new_password",
        labelText: "Пароль",
        placeholder: "********",
    },

    OLD_PASSWORD_FORM_FIELD: {
        name: "old_password",
        labelText: "Старый пароль",
        placeholder: "********",
    },

    MODAL_FORM_FIELDS: [
        {
            name: "lastname",
            labelText: "Фамилия",
            placeholder: "Иванов",
            required: true,
        },
        {
            name: "firstname",
            labelText: "Имя",
            placeholder: "Кирилл",
            required: true,
        },
        {
            name: "middlename",
            labelText: "Отчество",
            placeholder: "Петрович",
            required: false,
        },
        {
            name: "email",
            labelText: "eMail",
            placeholder: "xxx@xxx.xxx",
            required: true,
        },
    ],
    STATUS_FORM_FIELD: {
        name: "status",
        labelText: "Статус",
        type: "select",
        options: Object.keys(userStatuses).map(key => ({value: key, label: userStatuses[key]})),
        required: true,
        initialValue: "active"
    },
    ROLE_FORM_FIELD: {
        name: "user_type",
        labelText: "Роль",
        type: "select",
        options: Object.keys(userTypes).map(key => ({value: key, label: userTypes[key]})),
        required: true,
    }
});