import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ROUTES_PATHS, ROUTES_TITLES} from "../../router/routes";
import {createUser, getUsers, removeUser, updateUser, USERS} from "../../store/slices/users";
import {AUTH} from "../../store/slices/auth";
import {CLIENTS, getClients} from "../../store/slices/clients";
import {ROLES, ROLES_TITLES, STATUSES_TITLES} from "../../constants";

import LoaderSpinner from "../../primitives/LoaderSpinner";
import Table from "../../primitives/Table";
import CreateUser from "./components/CreateUser";
import EditUserModal from "./components/EditUserModal";

import styles from "../../App.module.scss";
import {UilEdit, UilTrash} from "@iconscout/react-unicons";
import {getUserStatus, getUserType} from "store/slices/constants";

const Users = () => {
    const dispatch = useDispatch();
    const users = useSelector(state => state[USERS]);
    const clients = useSelector(state => state[CLIENTS]);
    const auth = useSelector(state => state[AUTH]);
    const [editingUser, setEditingUser] = useState(null);
    const [page, setPage] = useState(1);
    const pageSize = 10;

    const userStatus = useSelector(getUserStatus);
    const userType = useSelector(getUserType);

    const COLUMNS = [{key: 'full_name', displayName: 'ФИО'}, {
        key: 'status',
        displayName: 'Статус',
        value: (item) => userStatus(item.status)
    }, {key: 'email', displayName: 'eMail'}, {key: 'requests_limit', displayName: 'Лимит запросов'}, {
        key: 'requests_available',
        displayName: 'Доступно запросов'
    }];

    const CLIENT_COLUMN = {key: 'client', displayName: 'Клиент-владелец', value: (item) => item.company?.name};
    const ROLE_COLUMN = {key: 'user_type', displayName: 'Роль', value: (item) => userType(item.user_type)};

    const isUserSuperAdmin = auth.entity && auth.entity.role === ROLES.SUPER_ADMIN;
    // console.log(isUserSuperAdmin)

    const handleEdit = useCallback((id) => {
        setEditingUser(users.entities.find((u) => u.id === id));
    }, [users.entities]);

    const handleCloseEditing = useCallback(() => {
        setEditingUser(null);
    }, []);

    const handleCreateUser = ({client, ...userData}) => {
        return dispatch(createUser({...userData, company_id: client}))
            .unwrap()
            .then(() => ({}))
            .catch((error) => ({error}));
    };

    const handleUpdateUser = ({client, ...userData}) => {
        return dispatch(updateUser({...userData, id: editingUser.id, company_id: client}))
            .unwrap()
            .then(() => ({}))
            .catch((error) => ({error}));
    };

    const handleRemoveUser = useCallback((id) => {
        console.log(id)
        return dispatch(removeUser(id))
            .unwrap()
            .then(() => ({}))
            .catch((error) => ({error}));
    }, [dispatch]);

    const clientsNamesById = useMemo(() => {
        if (!clients.entities) return null;
        const dict = {}
        clients.entities.forEach((e) => dict[e.id] = e.name);
        return dict;
    }, [clients.entities])

    useEffect(() => {
        dispatch(getUsers({page, pageSize}));
        if (isUserSuperAdmin) dispatch(getClients({page: 1, pageSize: 100000}));
    }, [isUserSuperAdmin, page, dispatch]);

    const translatedUsers = useMemo(() => {
        if (!users.entities) return null;
        let result = users.entities.map((user) => ({
            ...user, fullName: `${user.surname} ${user.name} ${user.middlename}`, status: STATUSES_TITLES[user.status],
        }));

        if (isUserSuperAdmin && clientsNamesById) {
            result = result.map((user) => ({
                ...user, role: ROLES_TITLES[user.role], client: clientsNamesById[user.company_id]
            }))
        }

        return result;
    }, [users.entities, clientsNamesById, isUserSuperAdmin]);

    const columns = isUserSuperAdmin ? [...COLUMNS, CLIENT_COLUMN, ROLE_COLUMN] : COLUMNS;
    const actions = useMemo(() => [{
        key: 'edit',
        title: 'Редактировать',
        icon: <UilEdit size="20" color="#333333"/>,
        handle: handleEdit
    }, {
        key: "remove",
        title: "Удалить",
        icon: <UilTrash size="20" color="#333333"/>,
        handle: handleRemoveUser,
    }], [handleEdit, handleRemoveUser]);

    if (isUserSuperAdmin && !clients.entities) return <LoaderSpinner/>

    return (<div>
        <h2 className={styles.MobileTitle}>{ROUTES_TITLES[ROUTES_PATHS.USERS]}</h2>
        <CreateUser onCreateUser={handleCreateUser} clients={clients} isUserSuperAdmin={isUserSuperAdmin}/>
        {translatedUsers ? <Table data={translatedUsers} total={users.total} page={page} pageSize={pageSize} setPage={setPage}
                                  columns={columns} actions={actions}/> : <LoaderSpinner/>}
        <EditUserModal
            user={editingUser}
            onClose={handleCloseEditing}
            onUpdateUser={handleUpdateUser}
            clients={clients.entities}
            isUserSuperAdmin={isUserSuperAdmin}
        />
    </div>);
};

export default React.memo(Users);
