import axios, {AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {redirect} from 'react-router-dom';
import {logout, logoutUser, refreshAccessToken} from './store/slices/auth';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import isTokenExpiringSoon from "./utils/checkToken";
// import store from "./store";

const baseURL = process.env.REACT_APP_API_URL;

let accessToken = "123";

const getAccessToken = () => accessToken;

const axiosInstance: AxiosInstance = axios.create({
    baseURL,
    // headers: { 'X-Authorization': accessToken }
});


// Функция для обработки ошибок
const handleError = (error: AxiosError) => {
    let message = 'Произошла ошибка на сервере';

    // Проверка на наличие поля error с description
    if (error.response && error.response.data) {
        const data = error.response.data as any;

        if (data.error && data.error.description) {
            message = data.error.description;
        } else if (data.message) {
            message = data.message;
        }
    } else if (error.request) {
        message = 'Сервер не отвечает, попробуйте позже';
    } else {
        message = 'Ошибка при настройке запроса';
    }
    console.log(message)
    toast.error(`Ошибка: ${message}`);

    // Прокидываем ошибку дальше
    return Promise.reject(error);
};

// Функция для обработки успешных ответов
const handleResponse = (response: AxiosResponse) => {
    // console.log('Ответ получен:', response);
    return response;
};

const handleRequest = async (config: InternalAxiosRequestConfig) => {
    // console.log('handleRequest:', config);
    const { default: store } = await import('./store');
    let token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');

    // console.log('token:', token);
    // console.log('refreshToken:', refreshToken);

    if (config.url === 'auth/refresh') {
        return config;
    }
    // TODO рефрешить токен для сокетов


    if (token && refreshToken) {
        if (isTokenExpiringSoon(token)) {
            // console.log('token expired', token)
            try {
                token = await store.dispatch(refreshAccessToken(refreshToken)).unwrap();
                // console.log('token new', token)
            } catch (err) {
                logoutUser();
                return Promise.reject(err);
            }
        }
    }
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
};

const handleRequestError = (error: AxiosError) => {
    console.log('handleRequestError:', error);
    return Promise.reject(error);
};


// Устанавливаем перехватчики
axiosInstance.interceptors.response.use(handleResponse, handleError);
axiosInstance.interceptors.request.use(handleRequest, handleRequestError);

let authorized = (headers?: any) => ({
    // headers: { 'X-Authorization': accessToken }
    headers: {Authorization: `Bearer ${accessToken}`, ...headers}
});

// export const formPost = {
//     'Content-Type': "application/x-www-form-urlencoded'"
// };

// export const multipartFormPost = {
//     'Content-Type': "multipart/form-data"
// };

export const handleIOError = (reject?: (res: any) => void) => (error: any) => {
    console.error(error);
    if (!error.response) {
        logoutUser();
        accessToken = "";
        redirect("/login");
        return;
    }
    switch (error.response.status) {
        case 401:
            logoutUser();
            accessToken = "";
            redirect("/login");
            return;
    }
    console.log(error.response.status);
    if (reject) {
        reject(error);
    }
};

// export const handleErrorDescription = (error: any) => {
//     console.log(error);
//     // console.log(error.response);
//     // console.log(error.response.status);
// };

const setAccessToken = (token?: string) => {
    accessToken = token || "";
};

export default axiosInstance;
export {authorized, getAccessToken, setAccessToken};