import { useEffect } from "react";

const useClickOutside = ({ elementRef, excludeRefs = [], callback }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (elementRef.current && !elementRef.current.contains(event.target)) {
                // Check if the click target is not within the element or its excluded refs
                if (!excludeRefs.some(ref => ref.current && ref.current.contains(event.target))) {
                    callback();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [elementRef, excludeRefs, callback]);
};

export default useClickOutside;
