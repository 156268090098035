import React, {useEffect, useMemo, useState} from "react";
import {UilExternalLinkAlt, UilFileDownloadAlt, UilRefresh} from '@iconscout/react-unicons'

import Table from "../../primitives/Table";
import formatDate from "../../utils/formatDate";
import {ROUTES_PATHS, ROUTES_TITLES} from "../../router/routes";
import FilterComponent, {Filter, FilterType, SelectedFilters} from "../../primitives/Filter";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, IAuthUser, IRootState} from "../../store";
import {getUsers, IUser, USERS, UsersState} from "../../store/slices/users";
import LoaderSpinner from "../../primitives/LoaderSpinner";

import styles from "../../App.module.scss";
import {AUTH} from "../../store/slices/auth";
import {ROLES} from "../../constants";
import {getReports, ReportsState, restartReport, SEARCH, setReportStatus} from "../../store/slices/search";
import wsService from "./service.js";
import {getAccessToken} from "../../api";
import {IReport, IReportRequest} from "../../store/slices/search/types";
import {CLIENTS, getClients} from "../../store/slices/clients";
import {getRequestSource, getRequestStatus, getRequestStatusDescription, getRequestType} from "../../store/slices/constants";


const formatRequestData = (requestData: IReportRequest): string => {
    const propertiesOrder: (keyof IReportRequest)[] = [
        "unstructured",
        "surname",
        "name",
        "patronymic",
        "birth_day",
        "birth_month",
        "birth_year",
        "social_security_number",
        "individual_enterpreneur_registration_code",
        "tax_code",
        "vehicle_number",
        "vehicle_vin",
        "driver_license",
        "driver_license_date",
        "document",
        "email",
        "phone",
        "address_geo",
        "company_tax_id",
        "company_prsn"
    ];

    const values: string[] = [];
    const birthDateParts: string[] = [];

    for (const prop of propertiesOrder) {
        if (requestData[prop] !== null) {
            if (prop === "birth_day" || prop === "birth_month" || prop === "birth_year") {
                // Если это день, месяц или год, добавляем в массив для даты
                birthDateParts.push(requestData[prop]!);
            } else {
                // Для остальных значений просто добавляем в массив
                values.push(requestData[prop]!);
            }
        }

        // После обработки последнего элемента даты (birth_year), добавляем дату в values
        if (prop === "birth_year" && birthDateParts.length > 0) {
            values.push(birthDateParts.join('.'));
        }
    }

    return values.join(' ');
};


const reportName = (report: IReport) => {
    if (report.request_data.unstructured) {
        return report.request_data.unstructured;
    }
    return formatRequestData(report.request_data);
};


const Reports = () => {
    const users = useSelector<IRootState, UsersState>((state) => state[USERS]);
    const companies = useSelector<IRootState, any>((state) => state[CLIENTS]);
    const userRef = useMemo(() => users.entities?.reduce((s: any, user: IUser) => {
        s[user.id] = user;
        return s;
    }, {}), [users.entities]);
    const companiesRef = useMemo(() => companies.entities?.reduce((s: any, company: any) => {
        s[company.id] = company;
        return s;
    }, {}), [companies.entities]);
    const authUser = useSelector<IRootState, IAuthUser | null>((state) => state[AUTH].entity);
    const isAuthUserAnyAdmin = authUser && (authUser.role === ROLES.CLIENT_ADMIN || authUser.role === ROLES.SUPER_ADMIN);

    const reports = useSelector<IRootState, ReportsState>(state => state[SEARCH].reports);
    const requestType = useSelector(getRequestType);
    const requestStatus = useSelector(getRequestStatus);
    const requestStatusDescription = useSelector(getRequestStatusDescription);
    const requestSource = useSelector(getRequestSource);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState<SelectedFilters | null>(null);
    const pageSize = 10;
    const dispatch = useDispatch<AppDispatch>();

    const COLUMNS: any = [
        {key: 'entityName', displayName: 'Запрос', value: reportName},
        {key: 'date', displayName: 'Дата', value: (report: IReport) => formatDate(report.created)},
//        { key: 'item',value: (report: IReport) =>JSON.stringify(report)}
    ];

    const USER_COLUMN: any = {
        key: 'userFullName', displayName: 'Пользователь', value: (report: IReport) => {
            if (report.user_id && userRef) {
                const user: any = userRef[report.user_id];
                if (user) return user.full_name;
            }
            return report.user_id;
        }
    }

    const COMPANY_COLUMN: any = {
        key: 'companyName', displayName: 'Компания', value: (report: IReport) => {
            if (report.company_id && companiesRef) {
                const company: any = companiesRef[report.company_id];
                if (company) return company.name;
            }
            return report.company_id;
        }
    };

    const TYPE_COLUMN: any = {
        key: "request_type",
        displayName: "Тип запроса",
        value: (report: any) => requestType(report.request_type)
    };

    const STATUS_COLUMN: any = {
        key: "status",
        displayName: "Статус",
        value: (report: any) => requestStatus(report.status)
    };

    const SOURCE_COLUMN: any = {
        key: "request_source",
        displayName: "Источник запроса",
        value: (report: any) => requestSource(report.request_source)
    };

    const STATUS_DESCRIPTION_COLUMN: any = {
        key: "status_description",
        displayName: "Сообщение",
        value: (report: any) => requestStatusDescription(report.status_description)
    };

    const handleRestartReport = (request_id: number) => {
        dispatch(restartReport(request_id));
    };

    const findReport = (id: any) => reports.entities.find(rep => rep.id === id);
    const actions = useMemo(() => [
        (item: IReport) => item.status == "fail" || item.status == "done_without_context" ? ({
            key: 'refresh', handle(id: any) {
                handleRestartReport(id)
            }, icon: <UilRefresh/>, title: 'Перезапустить'
        }) : undefined,
        (item: IReport) => item.status == "done" || item.status == "done_without_context" ? ({
            key: 'link', handle(id: any) {
                const report = findReport(id);
                console.log("LINK", report);
                if (report?.report_url_html) {
                    window.open(report.report_url_html);
                }
            }, icon: <UilExternalLinkAlt/>, title: 'Открыть'
        }) : undefined,
        (item: IReport) => item.status == "done" || item.status == "done_without_context" ? ({
            key: 'download', handle(id: any) {
                const report = findReport(id);
                if (report?.report_url) {
                    window.open(report.report_url);
                }
            }, icon: <UilFileDownloadAlt/>, title: 'Выгрузить'
        }) : undefined
    ], [reports.entities]);

    const filters: Filter[] = useMemo(() => {
        if (!users.entities || !authUser) return [];
        if (isAuthUserAnyAdmin) {
            return [{
                field: 'user',
                label: 'Пользователь',
                type: FilterType.Select,
                options: users.entities.map((u) => ({
                    label: `${u.lastname} ${u.firstname ? u.firstname[0] : ''}. ${u.middlename ? u.middlename[0] : ''}.`,
                    value: String(u.id)
                }))
            },
                {field: 'date', label: 'Период', type: FilterType.DateRange}
            ]
        } else {
            return [{field: 'date', label: 'Период', type: FilterType.DateRange}]
        }
    }, [users.entities, isAuthUserAnyAdmin, authUser]);

    // const formattedData = useMemo(() => DATA.map((e) => ({ ...e, date: formatDate(e.date) })), []);

    const columns = useMemo(() => {
        if (!authUser) return null;

        let result = [];
        if (authUser.role === ROLES.SUPER_ADMIN) {
            result.push({key: 'id', displayName: 'id', value: (report: IReport) => report.id})
        }
        result = [...result, ...COLUMNS]
        result.push(TYPE_COLUMN)
        if (isAuthUserAnyAdmin) result.push(USER_COLUMN)
        if (authUser.role === ROLES.SUPER_ADMIN) {
            result.push(COMPANY_COLUMN)
        }
        result.push(SOURCE_COLUMN, STATUS_COLUMN, STATUS_DESCRIPTION_COLUMN);
        return result;
    }, [isAuthUserAnyAdmin, authUser, users, companies])

    useEffect(() => {
        if (authUser) {
            wsService.attach(authUser.id, getAccessToken(), (message: any) => {
                if (message.report_id) {
                    dispatch(setReportStatus({
                        id: message.report_id,
                        status: message.status,
                        status_description: message.text
                    }))
                }
            });
            wsService.connect();
            return () => wsService.disconnect();
        }
    }, [authUser]);

    useEffect(() => {
        // console.log("FILTER", filter);
        dispatch(getReports({filter, page, pageSize}));
    }, [page, filter]);

    useEffect(() => {
        dispatch(getUsers({page: 1, pageSize: 1000000}));
        //@ts-ignore
        dispatch(getClients({page: 1, pageSize: 1000000}));
    }, []);

    // if (reports.loading || !filters) return <LoaderSpinner />;

    return (
        <div>
            <h2 className={styles.MobileTitle}>{ROUTES_TITLES[ROUTES_PATHS.REPORTS]}</h2>
            <FilterComponent filters={filters} applyFilters={setFilter}/>
            {(reports.loading || !filters ?
                    <LoaderSpinner/> :
                    <Table data={reports.entities} columns={columns} actions={actions} total={reports.total} page={page}
                           setPage={setPage}/>
            )}
        </div>
    );
};

export default React.memo(Reports);