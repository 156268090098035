import React, {useMemo} from "react";
import {useLocation} from "react-router-dom";
import { UilAngleRight } from '@iconscout/react-unicons'

import {ROUTES_TITLES} from "../../router/routes";
import styles from "./index.module.scss";

const findRouteTitle = (locationPathname) => {
    const key = Object.keys(ROUTES_TITLES).find((path) => locationPathname.includes(path));
    return ROUTES_TITLES[key];
};
const Breadcrumbs = () => {
    const location = useLocation();
    const routeTitle = useMemo(() => findRouteTitle(location.pathname), [location.pathname]);

    if (!routeTitle) return null;
    return (
        <div className={styles.Breadcrumbs}>
            <UilAngleRight size={38}/>
            {routeTitle}
        </div>
    );
};

export default React.memo(Breadcrumbs);