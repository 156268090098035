import React, {createContext, useMemo} from "react";
import {Outlet} from "react-router-dom";

import Tabs from "../../components/Tabs";
import {ROUTES_PATHS, ROUTES_TITLES} from "../../router/routes";
import {StructuredReportNames} from "../../store/slices/search/types";
import {FormValues} from "./components/Individual";
import {getStructuredReport, SEARCH} from "../../store/slices/search";
import {AppDispatch, IRootState} from "../../store";
import appStyles from "../../App.module.scss";
import {useDispatch, useSelector} from "react-redux";

import styles from "../Search/index.module.scss";

const handleStructuredReport = (dispatch: AppDispatch) => {
    return (values: FormValues<StructuredReportNames>) => {
        const filteredValues: { [key in StructuredReportNames]?: string } = {};
        Object.entries(values).forEach(([name, value]) => {
            if (value) filteredValues[name as StructuredReportNames] = value;
        });
        // @ts-ignore
        return dispatch(getStructuredReport(filteredValues));
    };
};

export const StructuredSearchContext = createContext<{ handleStructuredReport: ReturnType<typeof handleStructuredReport> | Function }>({
    handleStructuredReport: () => {
    }
})
const StructuredSearch = () => {
    const dispatch = useDispatch<AppDispatch>();
    // const structuredState = useSelector<IRootState, SearchState['structured']>((state) => state[SEARCH].structured)
    const report = useSelector<IRootState, string | null>(state => state[SEARCH].structured.entity);

    const tabs = useMemo(() => [
        {path: 'individual', title: 'Физическое лицо'},
        {path: 'legal', title: 'Организация'},
        {path: 'vehicle', title: 'Транспорт'}
    ], []);

    return (
        <StructuredSearchContext.Provider value={{handleStructuredReport: handleStructuredReport(dispatch)}}>
            <h2 className={appStyles.MobileTitle}>{ROUTES_TITLES[ROUTES_PATHS.STRUCTURED_SEARCH.INDEX]}</h2>
            <Tabs tabs={tabs}/>
            <div className={styles.Search}>
                <Outlet/>
                {report && (
                    <p>Ваш запрос обрабатывается. Результаты можете посмотреть на <a href="/reports">странице отчетов</a></p>)}
                {/*{structuredState.entity && <IframeRenderer htmlContent={structuredState.entity}/>}*/}
            </div>
        </StructuredSearchContext.Provider>
    );
};

export default React.memo(StructuredSearch);
