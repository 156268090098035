import React, { useMemo } from "react";
import { UilUserPlus } from '@iconscout/react-unicons';

import Form from "../../../../primitives/Form";
import Modal from "../../../../primitives/Modal";

//import {LIMIT_FORM_FIELD, MODAL_FORM_FIELDS, ROLE_FORM_FIELD} from "../../constants";
import formFields from "../../fields.js";
import { ROLES } from "../../../../constants";
import { getUserStatuses } from "store/slices/constants";
import { getUserTypes } from "store/slices/constants";
import { useSelector } from "react-redux";

const EditUserModal = ({ user, onUpdateUser, onClose, clients, isUserSuperAdmin }) => {

    const userStatuses = useSelector(getUserStatuses);
    const userTypes = useSelector(getUserTypes);

    const { LIMIT_FORM_FIELD, MODAL_FORM_FIELDS, ROLE_FORM_FIELD, OLD_PASSWORD_FORM_FIELD, NEW_PASSWORD_FORM_FIELD, SUPERUSER_FORM_FIELD, STATUS_FORM_FIELD } = formFields({ userStatuses, userTypes });

    const fields = useMemo(() => {
        if (!user) return null;

        let result = [...MODAL_FORM_FIELDS, OLD_PASSWORD_FORM_FIELD, NEW_PASSWORD_FORM_FIELD];
        // console.log(isUserSuperAdmin)
        if (isUserSuperAdmin) {
            result.push(ROLE_FORM_FIELD);
            result.push(SUPERUSER_FORM_FIELD);
            result.push({
                name: "client",
                labelText: "Клиент-владелец",
                type: "select",
                options: clients.map((e) => ({ label: e.name, value: e.id })),
                required: false,
                conditionalFields: [
                    {field: ROLE_FORM_FIELD.name, value: [ROLES.USER, ROLES.CLIENT_ADMIN]}
                ]
            })
            result.push({ ...LIMIT_FORM_FIELD, conditionalFields: [{ field: ROLE_FORM_FIELD.name, value: ROLES.USER }] })
        } else if (user.role === ROLES.USER) result.push(LIMIT_FORM_FIELD);

        result = result.map((field) => ({ ...field, initialValue: user[field.name] }));
        // if (isUserSuperAdmin && clients) result.push({
        //     name: "company_id",
        //     labelText: "Клиент-владелец",
        //     type: "select",
        //     options: clients.map((e) => ({ label: e.name, value: e.id })),
        //     required: true,
        //     initialValue: user.company_id
        // });
        result.push(STATUS_FORM_FIELD);
        return result
    }, [user, clients, isUserSuperAdmin, LIMIT_FORM_FIELD, MODAL_FORM_FIELDS, ROLE_FORM_FIELD, OLD_PASSWORD_FORM_FIELD, NEW_PASSWORD_FORM_FIELD, SUPERUSER_FORM_FIELD, STATUS_FORM_FIELD]);

    return (
        <Modal isOpen={user} onClose={onClose} title="Изменить пользователя">
            <Form
                column
                fields={fields}
                buttonText="Сохранить"
                buttonIcon={<UilUserPlus />}
                onSubmit={onUpdateUser}
            />
        </Modal>
    );
};

export default React.memo(EditUserModal);
