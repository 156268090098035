import React from "react";

import Input from "../../../Input";
import GroupInput from "../../../GroupInput";
import styles from "./index.module.scss";
import RangeInput from "../../../RangeInput";
import 'react-datepicker/dist/react-datepicker.css';
import DateInput from "primitives/DateInput";

const GroupField = ({className, title, values, onChangeField, childrenFields}) => {
    const withValues = (fields) => fields.map((field) => ({
        ...field,
        value: values[field.name],
        onChange: onChangeField(field.name)
    }));

    const resolveInput = (field) => {
        if (field.type === 'range') {
            return (
                <RangeInput
                    labelText={field.labelText}
                    value={values[field.name]}
                    onChange={onChangeField(field.name)}
                    minValue={field.minValue}
                    maxValue={field.maxValue}
                />
            );
        } else if (field.type === "date") {
            let date = values[field.name];
            if (date && !(date instanceof Date)) {
                date = new Date(date);
            }
            return (
                <div className={styles.Field} key={field.name}>
                    <DateInput
                        value={date}
                        onChange={onChangeField(field.name)}
                        labelText={field.labelText}
                        placeholder={field.placeholder}
                    />
                </div>
            );
        } else {

            return (
                <Input
                    {...field}
                    value={values[field.name]}
                    onChange={onChangeField(field.name)}
                    key={field.name}
                />
            )
        }
    }

    return (
        <div className={className}>
            {title && <h4 className={styles.Title}>{title}</h4>}
            <div className={styles.Inputs}>
                {childrenFields.map((field) => !field.children ? resolveInput(field) : (
                    <GroupInput
                        {...field}
                        labelText={field.groupTitle}
                        inputs={withValues(field.children)}
                        key={field.name}
                    />
                ))}
            </div>
        </div>
    );
};

export default React.memo(GroupField);
