import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UilEdit, UilTrashAlt, UilUserPlus} from "@iconscout/react-unicons";

import {ROUTES_PATHS, ROUTES_TITLES} from "../../router/routes";
import LoaderSpinner from "../../primitives/LoaderSpinner";
import Table from "../../primitives/Table";
import Form from "../../primitives/Form";
import Modal from "../../primitives/Modal";
import Button from "../../primitives/Button";
import styles from "../../App.module.scss";
import {
    getPackageOption,
    getPackageOptions,
    getPackageStatus,
    getPackageStatuses,
    getPackageType,
    getPackageTypes
} from "store/slices/constants";
import {createPackage, deletePackage, getPackages, PACKAGES, updatePackage} from "store/slices/packages";
import ConfirmModal from "components/ConfirmModal";

const Packages = () => {
    const packageStatus = useSelector(getPackageStatus);
    const packageStatuses = useSelector(getPackageStatuses);
    const packageType = useSelector(getPackageType);
    const packageTypes = useSelector(getPackageTypes);
    const packageOption = useSelector(getPackageOption);
    const packageOptions = useSelector(getPackageOptions);

    const COLUMNS = [
        {key: 'name', displayName: 'Название'},
        {key: 'status', displayName: 'Статус', value: (item) => packageStatus(item.status)},
        {key: 'package_type', displayName: 'Тип пакета', value: (item) => packageType(item.package_type)},
        {key: 'package_option', displayName: 'Опции', value: (item) => packageOption(item.package_option)},
        {key: 'limits', displayName: 'Лимит'},
        {key: 'price', displayName: 'Цена'},
        // { key: 'req_limit', displayName: 'Лимит запросов', value: (item) => item.package?.limits },
    ];

    const CREATE_FIELDS = [
        {
            name: "name",
            labelText: "Название",
            required: true,
            placeholder: 'Стандартный'
        },
        {
            name: "package_type",
            labelText: "Тип пакета",
            type: "select",
            options: Object.keys(packageTypes).map(key => ({value: key, label: packageTypes[key]})),
            required: true,
            initialValue: Object.keys(packageTypes)[0]
        },
        {
            name: "package_option",
            labelText: "Опции пакета",
            type: "select",
            options: Object.keys(packageOptions).map(key => ({value: key, label: packageOptions[key]})),
            required: true,
            initialValue: Object.keys(packageOptions)[0]
        },
        {
            name: "limits",
            labelText: "Лимит запросов",
            placeholder: "1000",
            type: "number",
            required: true,
        },
        {
            name: "price",
            labelText: "Цена",
            placeholder: "1000",
            type: "number",
            required: true,
        }
    ];
    const EDIT_FIELDS = CREATE_FIELDS.concat([
        {
            name: "status",
            labelText: "Статус",
            type: "select",
            options: Object.keys(packageStatuses).map(key => ({value: key, label: packageStatuses[key]})),
            required: true,
            initialValue: "active"
        },
    ]);

    const dispatch = useDispatch();
    const packages = useSelector(state => state[PACKAGES]);
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const [editingPackage, setEditingPackage] = useState(null);
    const [isCreatingOpen, setIsCreatingOpen] = useState(false);
    const [deletingPackage, setDeletingPackage] = useState(null);

    const openEditingModal = useCallback((id) => {
        setEditingPackage(packages.entities.find((c) => c.id === id));
    }, [packages.entities]);
    const closeEditingModal = useCallback(() => setEditingPackage(null), []);

    const openCreatingModal = useCallback(() => setIsCreatingOpen(true), []);
    const closeCreatingModal = useCallback(() => setIsCreatingOpen(false), []);

    const handleCreatePackage = useCallback((pckg) => {
        return dispatch(createPackage(pckg))
            .unwrap()
            .then(() => closeCreatingModal())
            .then(() => ({}))
            .catch((error) => ({error}));
    }, []);

    const handleUpdatePackage = useCallback((pckg) => {
        return dispatch(updatePackage({id: editingPackage.id, ...pckg}))
            .unwrap()
            .then(() => ({}))
            .catch((error) => ({error}));
    }, [editingPackage]);

    const handleDeletePackage = useCallback((id) => setDeletingPackage(packages.entities?.find(p => p.id === id)), [packages]);

    const onCloseConfirmModal = useCallback(() => setDeletingPackage(null), []);
    const onDeletePackage = useCallback(() => {
        return dispatch(deletePackage(deletingPackage.id)).then(() => {
            setDeletingPackage(null);
        });
    }, [deletingPackage]);

    useEffect(() => {
        dispatch(getPackages({page, pageSize}))
    }, []);

    const actions = useMemo(() => [{
        key: 'edit',
        title: 'Редактировать',
        icon: <UilEdit size="20" color="#333333"/>,
        handle: openEditingModal
    }, {
        key: 'delete',
        title: 'Удалить',
        icon: <UilTrashAlt size="20" color="#333333"/>,
        handle: handleDeletePackage
    }], [openEditingModal, handleDeletePackage]);

    const editingFields = useMemo(() => editingPackage ? EDIT_FIELDS.map((f) => ({
        ...f,
        initialValue: editingPackage[f.name] || f.initialValue
    })) : null, [editingPackage]);

    // const translatedClients = useMemo(() => clients.entities ? clients.entities.map((c) => ({
    //     ...c,
    //     status: STATUSES_TITLES[c.status]
    // })) : null, [clients.entities]);

    return (
        <div>
            <h2 className={styles.MobileTitle}>{ROUTES_TITLES[ROUTES_PATHS.CLIENTS]}</h2>
            <Button icon={<UilUserPlus/>} onClick={openCreatingModal}>Создать пакет</Button>
            {(packages.loading && !packages.entities) && <LoaderSpinner/>}
            {packages.total &&
                <Table data={packages.entities} columns={COLUMNS} actions={actions} total={packages.total} page={page}
                       pageSize={pageSize}/>}
            <Modal isOpen={isCreatingOpen} onClose={closeCreatingModal} title="Добавить пакет">
                <Form
                    column
                    fields={CREATE_FIELDS}
                    buttonText="Сохранить"
                    buttonIcon={<UilUserPlus/>}
                    onSubmit={handleCreatePackage}
                />
            </Modal>
            <Modal isOpen={editingPackage} onClose={closeEditingModal} title="Изменить пакет">
                <Form
                    column
                    fields={editingFields}
                    buttonText="Сохранить"
                    buttonIcon={<UilUserPlus/>}
                    onSubmit={handleUpdatePackage}
                />
            </Modal>
            <ConfirmModal isOpen={!!deletingPackage} handleClose={onCloseConfirmModal} handleYes={onDeletePackage}
                          handleNo={onCloseConfirmModal} title="Удаление пакета"
                          text={`Удалить пакет ${deletingPackage?.name} ?`}
            />
        </div>
    );
};

export default React.memo(Packages);
