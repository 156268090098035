import React from "react";
import { UilHome } from '@iconscout/react-unicons'

import Button from "../../primitives/Button";
import styles from "./index.module.scss";

const NotFoundError = () => {
    return (
        <div>
            <h4 className={styles.Text}>Ошибка! По данному пути ничего не найдено.</h4>
            <Button to={'/'} icon={<UilHome size={16}/>} uppercase>Home</Button>
        </div>
    );
};

export default React.memo(NotFoundError);
