import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import inputStyles from '../Input/index.module.scss';
import styles from './index.module.scss';

export interface IDateRangeInputProps {
    startDate: Date | null;
    endDate: Date | null;
    onChangeStartDate: (date: Date | null) => void;
    onChangeEndDate: (date: Date | null) => void;
    labelText?: string;
    placeholders?: { start: string, end: string };
}

const DateRangeInput: React.FC<IDateRangeInputProps> = ({ startDate, endDate, onChangeStartDate, onChangeEndDate, labelText, placeholders = { start: 'От', end: 'До' } }) => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    return (
        <label className={inputStyles.Label}>
            {labelText && (
                <span>{labelText}</span>
            )}
            <div className={styles.DateRange}>
                {isMobile ? (
                    <>
                        <input
                            type="date"
                            className={inputStyles.Input}
                            value={startDate ? startDate.toISOString().split('T')[0] : ''}
                            onChange={(e) => onChangeStartDate(new Date(e.target.value))}
                        />
                        <span> - </span>
                        <input
                            type="date"
                            className={inputStyles.Input}
                            value={endDate ? endDate.toISOString().split('T')[0] : ''}
                            onChange={(e) => onChangeEndDate(new Date(e.target.value))}
                        />
                    </>
                ) : (
                    <>
                        <div>
                            <DatePicker
                                className={inputStyles.Input}
                                selected={startDate}
                                onChange={onChangeStartDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd.MM.yyyy"
                                placeholderText={placeholders.start}
                            />
                        </div>
                        <span> - </span>
                        <div>
                            <DatePicker
                                className={inputStyles.Input}
                                selected={endDate}
                                onChange={onChangeEndDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd.MM.yyyy"
                                minDate={startDate}
                                placeholderText={placeholders.end}
                            />
                        </div>
                    </>
                )}
            </div>
        </label>
    );
};

export default React.memo(DateRangeInput);
