import {IPaged} from './search/types';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ROLES} from "../../constants";
import axiosInstance, {authorized, handleIOError} from '../../api';

export interface IUser {
    id: number;
    company_id?: number;
    firstname: string;
    lastname: string;
    middlename?: string;
    status: 'active' | 'inactive';
    role: typeof ROLES[keyof typeof ROLES];
    req_limit?: number;
}

interface IUsers {
    items: IUser[];
    total: number;
}

export const USERS = 'users'
export const getUsers = createAsyncThunk<IUsers, IPaged>(
    `${USERS}/getUsers`,
    (params) => {
        return new Promise<IUsers>((resolve, reject) => {
            axiosInstance.post("users/query", {
                page_num: params.page,
                per_page: params.pageSize
            }, authorized()).then((res: any) => {
                resolve({
                    items: res.data.result,
                    total: res.data.count
                });
            }).catch(handleIOError(reject));
        });
    }
);

export const createUser = createAsyncThunk(
    `${USERS}/createUser`,
    async (userData: Omit<IUser, "role"> & { role?: IUser['role'] }) => {
        console.log("CREATE");
        try {
            return await new Promise<IUser>((resolve, reject) => {
                axiosInstance.post("users/create", userData, authorized()).then(res => {
                    resolve(res.data);
                }).catch(reject);
                // setTimeout(() => {
                //     if (userData.client_id === 1) reject();
                //     resolve({ role: ROLES.USER, ...userData }); // Default role protection
                // }, 1000);
            });
        } catch (error) {
            throw new Error('Ошибка сервера!');
        }
    }
);

export const updateUser = createAsyncThunk(
    `${USERS}/updateUser`,
    async (userData: Omit<IUser, "role"> & { role?: IUser['role'] }) => {
        try {
            return await new Promise<any>((resolve, reject) => {
                axiosInstance.put(`users/${userData.id}`, userData, authorized()).then(res => {
                    resolve(res.data);
                }).catch(reject);
                // setTimeout(() => {
                //     if (userData.client_id === 1) reject();
                //     resolve({ role: ROLES.USER, ...userData }); // Default role protection
                // }, 1000);
            });
        } catch (error) {
            throw new Error('Ошибка сервера!');
        }
    }
);

export const removeUser = createAsyncThunk(
    `${USERS}/removeUser`,
    async (id: number) => {
        try {
            return await new Promise<any>((resolve, reject) => {
                axiosInstance.delete(`users/${id}`, authorized()).then(res => {
                    resolve(res.data);
                }).catch(reject);
                // setTimeout(() => {
                //     if (userData.client_id === 1) reject();
                //     resolve({ role: ROLES.USER, ...userData }); // Default role protection
                // }, 1000);
            });
        } catch (error) {
            throw new Error('Ошибка сервера!');
        }
    }
);

export interface UsersState {
    entities: IUser[];
    total: number;
    loading: boolean;
    creating: {
        loading: boolean;
    };
    updating: {
        loading: boolean;
    };
}

const initialState: UsersState = {entities: [], total: 0, loading: false, creating: {loading: false}, updating: {loading: false}}

const clientsSlice = createSlice({
    name: USERS,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                // console.log("USERS", action.payload);
                state.entities = action.payload.items;
                state.total = action.payload.total;
                state.loading = false;
            })
            .addCase(createUser.pending, (state) => {
                state.creating.loading = true;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.creating.loading = false;
                if (state.entities) {
                    state.entities.push(action.payload);
                } else {
                    state.entities = [action.payload];
                }
            })
            .addCase(updateUser.pending, (state) => {
                state.updating.loading = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.updating.loading = false;
                if (!state.entities) return;

                const updatedUser = action.payload;

                const index = state.entities.findIndex(user => user.id === updatedUser.id);
                console.log("USER", updatedUser, state.entities, index);
                if (index !== -1) {
                    updatedUser.full_name = `${updatedUser.lastname}${updatedUser.firstname ? ' ' + updatedUser.firstname : ''}${updatedUser.middlename ? ' ' + updatedUser.middlename : ''}`.trim();
                    state.entities[index] = updatedUser;
                }
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                console.log(action)
                const userIdToRemove = action.meta.arg;
                state.entities = state.entities.filter(user => user.id !== userIdToRemove);
                state.total -= 1;
            });
    }
})

export default clientsSlice.reducer;
