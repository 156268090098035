import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { UilTimes } from '@iconscout/react-unicons';

import useClickOutside from "../../hooks/useClickOutside";
import styles from './index.module.scss';

const Modal = ({ isOpen, onClose, title, children }) => {
    const ref = useRef();
    const rootElement = document.getElementById('modal-root');

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            handleClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, handleKeyDown]);

    useClickOutside({ elementRef: ref, callback: handleClose });

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={styles.ModalOverlay}>
            <div className={styles.Modal} ref={ref}>
                <div className={styles.Header}>
                    <h3 className={styles.Title}>{title}</h3>
                    <button className={styles.CloseButton} onClick={handleClose}><UilTimes /></button>
                </div>
                <div className={styles.Body}>
                    {children}
                </div>
            </div>
        </div>,
        rootElement
    );
};

export default React.memo(Modal);
