import React, { useContext } from "react";
import Form from "../../../../primitives/Form";
import { StructuredSearchContext } from "../../index";

const FIELDS = [
    {
        name: "transport",
        children: [
            { name: 'vehicle_number', labelText: "Номер транспортного средства", placeholder: 'А000АА' },
            { name: 'vehicle_vin', labelText: "VIN-код транспортного средства", placeholder: '1VWAH7A31CC102891' }
        ]
    },
    {
        name: "documents",
        children: [
            // {name: 'document', labelText: "Документы", placeholder: 'Серия и номер паспорта'},
            { name: 'driver-license', labelText: 'Серия и номер водительского удостоверения' },
            { name: 'driver_license_date', labelText: 'Дата выдачи водительского удостоверения',type:'date' }
        ]
    }
];

const VALIDATE_COMBINATIONS = [
    [{ name: 'vehicle_number', errorLabel: 'Номер транспортного средства' }],
    [{ name: 'vehicle_vin', errorLabel: 'VIN-код транспортного средства' }],
    // [{ name: 'document', errorLabel: 'Серия и номер паспорта' }],
    [
        { name: 'driver-license', errorLabel: 'Серия и номер водительского удостоверения' },
        { name: 'driver_license_date', errorLabel: 'Дата выдачи водительского удостоверения',type:'date' }
    ]
];
const Vehicle = () => {
    const { handleStructuredReport } = useContext(StructuredSearchContext);

    return (
        <Form
            fields={FIELDS}
            onSubmit={handleStructuredReport}
            buttonText="Искать"
            headerText={undefined}
            buttonIcon={undefined}
            column={undefined}
            className={undefined}
            validateCombinations={VALIDATE_COMBINATIONS} />
    );
};

export default React.memo(Vehicle);
