import {Navigate} from "react-router-dom";

import Search from "../pages/Search";
import StructuredSearch from "../pages/StructuredSearch";
import Legal from "../pages/StructuredSearch/components/Legal";
import Individual from "../pages/StructuredSearch/components/Individual";
import Vehicle from "../pages/StructuredSearch/components/Vehicle";
import Auth from "../pages/Auth";
import Users from "../pages/Users";
import Clients from "../pages/Clients";
import Reports from "../pages/Reports";

import NotFoundError from "../components/NotFoundError";
import ForgotPassword from "../pages/ForgotPassword";
import Packages from "../pages/Packages";

export const ROUTES_PATHS = {
    HOME: "/",
    SEARCH: "/search",
    STRUCTURED_SEARCH: {
        INDEX: "/structured-search",
        INDIVIDUAL: "individual",
        LEGAL: "legal",
        VEHICLE: "vehicle"
    },
    STATS: "/stats",
    USERS: "/users",
    CLIENTS: "/clients",
    PACKAGES: "/packages",
    REPORTS: "/reports",
    AUTH: "/auth",
    FORGOT_PASSWORD: "/forgot-password",
    NOT_FOUND: "*",
};

export const ROUTES_TITLES = {
    [ROUTES_PATHS.SEARCH]: "Поиск",
    [ROUTES_PATHS.STRUCTURED_SEARCH.INDEX]: "Структурированный поиск",
    [ROUTES_PATHS.USERS]: "Пользователи",
    [ROUTES_PATHS.CLIENTS]: "Клиенты",
    [ROUTES_PATHS.PACKAGES]: "Пакеты",
    [ROUTES_PATHS.REPORTS]: "История отчетов"
};

export const HOME_ROUTES = [
    {path: ROUTES_PATHS.HOME, element: <Navigate to={ROUTES_PATHS.SEARCH}/>,},
    {path: ROUTES_PATHS.AUTH, Component: Auth},
    {path: ROUTES_PATHS.FORGOT_PASSWORD, Component: ForgotPassword},
    {path: ROUTES_PATHS.SEARCH, Component: Search},
    {
        path: ROUTES_PATHS.STRUCTURED_SEARCH.INDEX,
        Component: StructuredSearch,
        children: [
            {index: true, element: <Navigate to={ROUTES_PATHS.STRUCTURED_SEARCH.INDIVIDUAL}/>},
            {path: ROUTES_PATHS.STRUCTURED_SEARCH.LEGAL, Component: Legal},
            {path: ROUTES_PATHS.STRUCTURED_SEARCH.INDIVIDUAL, Component: Individual},
            {path: ROUTES_PATHS.STRUCTURED_SEARCH.VEHICLE, Component: Vehicle}
        ]
    },
    {path: ROUTES_PATHS.USERS, Component: Users},
    {path: ROUTES_PATHS.CLIENTS, Component: Clients},
    {path: ROUTES_PATHS.PACKAGES, Component: Packages},
    {path: ROUTES_PATHS.REPORTS, Component: Reports},
    {path: ROUTES_PATHS.NOT_FOUND, Component: NotFoundError}
];
