import React, {useCallback, useEffect, useState} from "react";
import cn from "classnames";

import Input from "../Input";
import Button from "../Button";
import Select from "../Select";
import GroupField from "./components/GroupField";
import styles from "./index.module.scss";
import Checkbox from "primitives/Checkbox";
import DateInput from "primitives/DateInput";

const SUCCESS_MESSAGE = 'Успех!';
const COMBINATION_ERROR = '_combination';

const getInitialValues = (fields) => {
    let values = {};
    fields.forEach((field) => {
        if (field.children) values = {...values, ...getInitialValues(field.children)};
        else values[field.name] = field.initialValue ?? '';
    });
    return values;
};

const Form = ({onSubmit, fields, headerText, buttonText, buttonIcon, column, className, validateCombinations = []}) => {
    const [values, setValues] = useState(getInitialValues(fields));
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    const ShouldConditionalFields = (conditionalFields, values) => {
        return conditionalFields.every((condition) => {
            if (Array.isArray(condition.value)) {
                // Проверяем, входит ли текущее значение в список допустимых значений
                return condition.value.includes(values[condition.field]);
            }
            // Поддержка старого формата: проверка на конкретное значение
            return values[condition.field] === condition.value;
        });
    };

    const validateForm = useCallback((formValues) => {
        let errors = {};
        const requiredFields = fields.filter((field) => {
            // if (field.conditionalFields && !field.conditionalFields.every(condition => values[condition.field] === condition.value)) {
            if (field.conditionalFields && !ShouldConditionalFields(field.conditionalFields, values)) {
                return false;
            }
            return field.required;
        }).map((field) => field.name);

        if (validateCombinations && validateCombinations.length) {
            const isValidCombination = validateCombinations.some(combination =>
                combination.every(({name}) => formValues[name])
            );

            if (!isValidCombination) {
                const combinationFields = validateCombinations.map(combination =>
                    combination.map(({errorLabel}) => errorLabel).join(' и ')
                ).join(', или ');

                errors[COMBINATION_ERROR] = `Необходимо заполнить поля: ${combinationFields}`;
            }
        }

        requiredFields.forEach((fieldName) => {
            if (!formValues[fieldName]) {
                errors[fieldName] = 'Поле обязательно к заполнению!';
            }
        });

        return errors;
    }, [fields, validateCombinations, values]);

    const onChangeField = useCallback((name) => (value) => {
        setSubmitStatus((submitStatus) => submitStatus ? null : submitStatus);
        setValues((prevState) => ({...prevState, [name]: value}));
        setErrors((prevErrors) => ({...prevErrors, [name]: '', [COMBINATION_ERROR]: ''}));
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        const formErrors = validateForm(values);
        if (Object.keys(formErrors).length !== 0) {
            setErrors(formErrors);
            console.error(formErrors);
            return;
        }

        // console.log("Values:", values); // Проверка данных

        // Заменяем пустые строки на null перед отправкой формы
        const sanitizedValues = {...values};
        Object.keys(sanitizedValues).forEach((key) => {
            if (sanitizedValues[key] === '') {
                sanitizedValues[key] = null;
            }
        });

        // console.log("Sanitized Values:", sanitizedValues); // Проверка данных

        setIsLoading(true);
        const data = await onSubmit(sanitizedValues);
        setIsLoading(false);
        if (!data) return;

        if (!data.error) {
            setSubmitStatus({success: true, message: typeof data === 'string' ? data : SUCCESS_MESSAGE});
        } else {
            // setSubmitStatus({success: false, message: data.error.message});
            setSubmitStatus({success: false, message: ''});
        }
    }, [onSubmit, values, validateForm]);


    const resolveField = useCallback((field) => {
        // const shouldRender = field.conditionalFields ? field.conditionalFields.every(condition => values[condition.field] === condition.value) : true;
        const shouldRender = field.conditionalFields
            ? ShouldConditionalFields(field.conditionalFields, values)
            : true;

        if (!shouldRender) return null;

        const errorElement = <span className={styles.Error}>{errors[field.name]}</span>;
        const descriptionElement = <span className={styles.Description}>{field.description}</span>

        if (field.children) {
            return (
                <div className={styles.Field} key={field.name}>
                    <GroupField
                        childrenFields={field.children}
                        title={field.groupTitle}
                        values={values}
                        onChangeField={onChangeField}
                        key={field.name}
                    />
                    {errors[field.name] && errorElement}
                    {field.description && descriptionElement}
                </div>
            );
        } else if (field.type === "select") {
            return (
                <div className={styles.Field} key={field.name}>
                    <Select
                        labelText={field.labelText}
                        value={values[field.name]}
                        options={field.options}
                        onChange={onChangeField(field.name)}
                    />
                    {errors[field.name] && errorElement}
                    {field.description && descriptionElement}
                </div>
            );
        } else if (field.type === "checkbox") {
            return (
                <div className={styles.Field} key={field.name}>
                    <Checkbox
                        labelText={field.labelText}
                        checked={values[field.name]}
                        onChange={onChangeField(field.name)}
                        key={field.name}
                    />
                    {errors[field.name] && errorElement}
                    {field.description && descriptionElement}
                </div>
            );
        } else if (field.type === "date") {
            let date = values[field.name];
            if (date && !(date instanceof Date)) {
                date = new Date(date);
            }
            return (
                <div className={styles.Field} key={field.name}>
                    <DateInput
                        value={date}
                        onChange={onChangeField(field.name)}
                        labelText={field.labelText}
                        placeholder={field.placeholder}
                    />
                </div>
            );
        } else {
            // console.log("FIELD", field);
            return (
                <div className={styles.Field} key={field.name}>
                    <Input
                        type={field.type}
                        labelText={field.labelText}
                        placeholder={field.placeholder}
                        value={values[field.name]}
                        onChange={onChangeField(field.name)}
                        key={field.name}
                    />
                    {errors[field.name] && errorElement}
                    {field.description && descriptionElement}
                </div>
            );
        }
    }, [values, onChangeField, errors]);

    useEffect(() => {
        setValues(getInitialValues(fields));
        setErrors({});
        setSubmitStatus(null);
    }, [fields]);

    return (
        <form className={cn(styles.Form, {[styles.column]: column}, className)} onSubmit={handleSubmit}>
            {headerText && <h3 className={styles.Header}>{headerText}</h3>}
            <div className={styles.Fields}>
                {fields.map(resolveField)}
            </div>
            <Button className={styles.Button} type="submit" icon={buttonIcon} loading={isLoading} uppercase>
                {buttonText}
            </Button>
            {!!errors['_combination'] && <div className={styles.Error}>{errors['_combination']}</div>}
            {!!submitStatus && submitStatus.message && (
                <p className={cn({[styles.Error]: !submitStatus.success, [styles.Success]: submitStatus.success})}>
                    {submitStatus.message}
                </p>
            )}
        </form>
    );
};

export default React.memo(Form);
